<template>
  <div class="app-container">
    <eHeader :query="query" @toQuery="toQuery" />
    <!--表格渲染 需要增加股票 todo-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="No." width="60"/>
      <el-table-column prop="catName" label="交易种类"/>
      <el-table-column label="交易品种" width="500">
         <template slot-scope="scope">
          <span>{{ parseSyms(scope.row.symList) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述"/>
      <!-- <el-table-column prop="remark" label="备注"/> -->
      <el-table-column prop="updateTime" label="更新时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/bussetup/tMt4TradeCategory'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/bussetup/mt4tradecategory/header'
import edit from '@/components/bussetup/mt4tradecategory/edit'
export default {
  name:'traca',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseSyms(syms){
      var symStr=''
      for(var i=0;i<syms.length;i++){
        symStr+=syms[i].name+','
      }
      return symStr
    },
    beforeInit() {
      this.url = '/crm/tMt4TradeCategory/queryCatAndSym'
      const sort = 'id,asc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const type = query.type
      const symbol = query.symbol
      const catName = query.catName
      if (symbol!=null && symbol!='') { this.params['symbol'] = symbol }
      if (catName!=null && catName!='') { this.params['catName'] = catName }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
