<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input v-model="query.catName" clearable placeholder="种类名称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.symbol" clearable placeholder="品种名称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','CATEGORY_ALL','CATEGORY_SYNC'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">同步种类</el-button>
      <eForm ref="form" :is-add="true"/>
    </div>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 'id', display_name: 'id' },
        { key: 'catName', display_name: '交易种类名称' }
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    }
  }
}
</script>
